<template>
	<div class="zhengce">
		<div class="left">
			<div class="cate">
				<div @click="changeBtn(item)" :class="{ 'cateitem': true, 'ischecked': item.checked }"
					v-for="item in policytype" :key="item.type">
					{{ item.name }}
				</div>
			</div>
			<div class="line"></div>

			<div class="citybtn">
				<div @click="tocity(item)" :class="{ 'btn': true, 'checkedbtn': item.checked }" v-for="item in citylist"
					:key="item.city_id">{{ item.city_name }}</div>
			</div>
			<div class="contentbox">
			
				<div v-if="content" v-html="content"></div>
				<div v-if="content===''" style="font-size:16px;color:#999;" >
					暂无数据...
				</div>
			</div>

		</div>
		<div class="right">

			<div class="rheader">
				<blue-title>政策文件</blue-title>
			</div>
			<div class="linebox">
				<div class="line" @click="downfile(item)" v-for="item in filelist" :key="item.id">
					<div class="showname">
						{{ item.name }}
					</div>
					<div class="showtext">{{ item.created }}</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { list,detail } from '@/api/website/zhengce'
import BlueTitle from '@/components/website/BlueTitle'
export default {
	name: 'PcWebsiteIntro',
	components: {
		BlueTitle
	},

	data() {
		return {
			sreach: '',
			policytype: [],
			citylist: [],
			content: '',
			filelist: [],
			page: 1,
			size: 15,
			total: 0,
		}
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {

			handler(v) {

				if (v.query.page > 1) {
					this.page = v.query.page
				} else {
					this.page = 1
				}
				this.getlist()
			}


		}
	},



	mounted() {
		if (this.$route.query.page > 1) {
			this.page = this.$route.query.page
		} else {
			this.page = 1
		}
		this.getlist()
	},

	methods: {
			//下载文件
			downfile(item) {
			window.open(item.file_url, 'top')
		},

		tocity(city) {
			const citylist = this.citylist.map(item => {
				if (item.city_id === city.city_id) {
					item.checked = true
				} else {
					item.checked = false
				}
				return item
			})
			this.citylist = citylist
			this.seachlist()

		},

		changeBtn(cur) {

			const list = this.policytype.map(item => {
				if (item.type === cur.type) {
					item.checked = true
				} else {
					item.checked = false
				}
				return item
			})
			this.policytype = list
			this.seachlist()
		},

		toDownload(item) {
			window.open(item.file_url, 'top')
		},


		handleCurrentChange(val) {
			const tourl = '/' + this.site.path + '/list/zhengce?page=' + val
			this.$router.push(tourl)
		},

		seachlist() {
			const city_id = this.citylist.filter(item => item.checked)
			const type = this.policytype.filter(item => item.checked)

			const params = { site_id: this.site.id, city_id: city_id[0], type: type[0] }

			detail(params).then(res => {
				this.content = res.data.content
				this.filelist = res.data.file_list
			})
		},

		getlist() {
			let params = { site_id: this.site.id }
			if (this.search !== '') {
				params.name = this.search
			}

			list(params).then(res => {
				this.policytype = res.data.policy_type
				this.citylist = res.data.city_list
				this.content = res.data.content
				this.filelist = res.data.file_list
			})

		}

	}

}

</script>


<style lang="scss" scoped>
.linebox {
	width: 356px;
	margin: 0 auto;

	.line {
		cursor: pointer;
		background: #fff;
		width: 336px;
		margin-left: 10px;
		height: 50px;
		border-bottom: solid 1px #F0F3F8;
		display: flex;

		.showrank {
			width: 48px;
			display: flex;
			justify-content: center;
			align-items: center;

			.rank {
				width: 20px;
				height: 20px;
				line-height: 20px;
				background: #E77817;
				color: #fff;
				font-weight: bold;
				font-size: 10px;
				border-radius: 4px;
			}

		}

	}

	.showname {
		flex: 1;
		margin-left: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		height: 50px;
		line-height: 50px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;


	}


	.showtext {

		width: 78px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		height: 50px;
		line-height: 50px;

	}

}

.line:last-child {
	border-bottom: none;
}

.contentbox {
	font-size:14px;
	text-align: left;
	height: auto;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	min-height: 200px;
}

.citybtn {

	width: 820px;
	margin-left: 20px;
	
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
	// box-sizing: border-box;
}

.btn {
	// width: 40px;
	height: 28px;
	padding: 0 10px;
	line-height: 28px;
	background: #F2F2F2;
	border-radius: 2px;
	margin-right: 10px;
	margin-top: 8px;
	cursor: pointer;
}

.checkedbtn {
	background: #00A3E4;
	color: #fff;
}


.cate {
	display: flex;

	.cateitem {
		width: 138px;
		height: 50px;
		background: #fff;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000;
		line-height: 50px;
		cursor: pointer;

	}

	.ischecked {
		width: 138px;
		height: 50px;
		color: #FFFFFF;
		background: #00A3E4;
	}

}

.line {
	width: 830px;
	height: 4px;
	background: #F2F2F2;
}

.zhengce {
	width: 1200px;
	height: auto;
	margin: 0 auto;
	display: flex;
	background: #F0F3F8;
	justify-content: space-between;

	.left {
		width: 830px;
		// height: 862px;
		background: #FFFFFF;
	}

	.right {
		width: 356px;
		height: 468px;
		background: #FFFFFF;
	}

}
</style>