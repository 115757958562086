import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//首页数据
export function zhengcelist(params) {
	return loginRequest({
    url: '/frontend/website/policy/home-list',
		method: 'post',
		data:Qs.stringify(params)
	})
}

//列表数据
export function list(params) {
	return loginRequest({
    url: '/frontend/website/policy/sub',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//详情数据
export function detail(params) {
	return loginRequest({
    url: '/frontend/website/policy/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}

